import uniqid from "uniqid";

import { requestBatch } from "../request";
import { TFAApi } from "../TwoFactorAuthentication";
import { UserApi } from "../User";

import { IProfileResponse } from "./types";

export const profileBatch = async (): Promise<IProfileResponse> => {
  const referalId = uniqid();
  const contactsId = uniqid();
  const tfaId = uniqid();
  const { dataReferalConfig, dataUserContactsConfig } = UserApi();
  const { tfaStatusConfig } = TFAApi();

  const response = await requestBatch([
    { ...dataReferalConfig, id: referalId },
    { ...dataUserContactsConfig, id: contactsId },
    { ...tfaStatusConfig, id: tfaId },
  ]);
  const result: IProfileResponse = {
    refferal: null,
    contacts: null,
    tfa: null,
  };
  response.forEach((res) => {
    let key = null;
    if (res.id === referalId) {
      key = "refferal";
    }
    if (res.id === contactsId) {
      key = "contacts";
    }
    if (res.id === tfaId) {
      key = "tfa";
    }
    if (key) {
      result[key as keyof IProfileResponse] = { ...res };
    }
  });
  return result;
};

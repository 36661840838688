import uniqid from "uniqid";

import { request } from "../request";
import { setupData } from "../utils";

import { QrCodeProps, TFAProps } from "./types";

export const TFAApi = () => {
  /** Генерация кода */
  const generateCodeConfig = setupData(uniqid("generate"), "profile.send-tfa-mail-code");

  const generateCode = async () => {
    return await request([{ ...generateCodeConfig }]);
  };

  /** Получение qr кода */
  const qrCodeConfig = setupData(uniqid("qr"), "profile.get-tfa-qr-code");

  const getQrCode = async (params: QrCodeProps) => {
    return await request([{ ...qrCodeConfig, params: params }]);
  };

  /** Активация */
  const activateTfaConfig = setupData(uniqid("activate"), "profile.activate-tfa");

  const activateTfa = async (params: TFAProps) => {
    return await request([{ ...activateTfaConfig, params: params }]);
  };

  /** Деaктивация */
  const deactivateTfaConfig = setupData(uniqid("deactivate"), "profile.inactivate-tfa");

  const deactivateTfa = async (params: TFAProps) => {
    return await request([{ ...deactivateTfaConfig, params: params }]);
  };

  /** Cтатуса аутентификации гугла */
  const tfaStatusConfig = setupData(uniqid("status"), "profile.get-tfa-google-status");

  const getTfaStatus = async () => {
    return await request([{ ...tfaStatusConfig }]);
  };

  return {
    qrCodeConfig,
    tfaStatusConfig,
    activateTfaConfig,
    generateCodeConfig,
    deactivateTfaConfig,
    getQrCode,
    activateTfa,
    generateCode,
    getTfaStatus,
    deactivateTfa,
  };
};

import uniqid from "uniqid";

import { UserProps } from "entities/User/model";

import { request } from "../request";
import { setupData } from "../utils";

import { NewPassword } from "./types";

export const UserApi = () => {
  /** Информация пользователя */
  const dataUserConfig = setupData(uniqid("user"), "profile.get-info");

  const getUser = async () => {
    return await request([{ ...dataUserConfig }]);
  };

  /** Редактирование информации о пользователе */
  const editUserConfig = setupData(uniqid("edit-user"), "profile.edit");

  const updateUser = async (user: UserProps) => {
    return await request([{ ...editUserConfig, params: user }]);
  };

  /** Получение токена */
  const getApiTokenConfig = setupData(uniqid("edit-user"), "profile.get-api-token");

  const getApiToken = async () => {
    return await request([{ ...getApiTokenConfig }]);
  };

  /** Генерация токена */
  const createApiTokenConfig = setupData(uniqid("edit-user"), "profile.create-api-token");

  const createApiToken = async () => {
    return await request([{ ...createApiTokenConfig }]);
  };

  /** Удаление токена */
  const deleteApiTokenConfig = setupData(uniqid("edit-user"), "profile.delete-api-token");

  const deleteApiToken = async () => {
    return await request([{ ...deleteApiTokenConfig }]);
  };

  /** Редактирование пароля */
  const editUserPasswordConfig = setupData(uniqid("password"), "profile.edit-user-password");

  const updateUserPassword = async (passwords: NewPassword) => {
    return await request([{ ...editUserPasswordConfig, params: passwords }]);
  };

  /** Баланс пользователя */
  const dataBalanceConfig = setupData(uniqid("balance"), "profile.get-balance");

  const getBalance = async () => {
    return await request([{ ...dataBalanceConfig }]);
  };

  /** Реферальная программа */
  const dataReferalConfig = setupData(uniqid("ref"), "profile.get-referral-info");

  const getReferal = async () => {
    return await request([{ ...dataReferalConfig }]);
  };

  /** Контакты пользователя */
  const dataUserContactsConfig = setupData(uniqid("contacts"), "profile.get-contact");

  const getUserContacts = async () => {
    return await request([{ ...dataUserContactsConfig }]);
  };

  /** Кол-во активных тикетов на веба */
  const dataUserActiveTicketsConfig = setupData(uniqid("tickets-count"), "profile.get-support-active-tickets-count");

  const getUserActiveTickets = async () => {
    return await request([{ ...dataUserActiveTicketsConfig }]);
  };

  /** Кол-во выплат */
  const dataUserPaymentsCountConfig = setupData(uniqid("payments-count"), "profile.get-billing-request-count");

  const getUserPaymentsCount = async () => {
    return await request([{ ...dataUserPaymentsCountConfig }]);
  };

  /** Выход из системы */
  const logoutConfig = setupData(uniqid("logout"), "logout");

  const logout = async () => {
    return await request([{ ...logoutConfig }]);
  };

  return {
    dataUserConfig,
    editUserConfig,
    dataBalanceConfig,
    dataReferalConfig,
    getApiTokenConfig,
    createApiTokenConfig,
    deleteApiTokenConfig,
    dataUserContactsConfig,
    dataUserPaymentsCountConfig,
    dataUserActiveTicketsConfig,
    logout,
    getUser,
    updateUser,
    getBalance,
    getReferal,
    getApiToken,
    createApiToken,
    deleteApiToken,
    getUserContacts,
    updateUserPassword,
    getUserPaymentsCount,
    getUserActiveTickets,
  };
};

import uniqid from "uniqid";

import { requestBatch } from "../request";
import { UserApi } from "../User";

import { IEditUserResponse } from "./types";

export const editUserBatch = async (): Promise<IEditUserResponse> => {
  const userId = uniqid();
  const balanceId = uniqid();
  const { dataUserConfig, dataBalanceConfig } = UserApi();

  const response = await requestBatch([
    { ...dataUserConfig, id: userId },
    { ...dataBalanceConfig, id: balanceId },
  ]);
  const result: IEditUserResponse = {
    user: null,
    balance: null,
  };
  response.forEach((res) => {
    let key = null;
    if (res.id === userId) {
      key = "user";
    }
    if (res.id === balanceId) {
      key = "balance";
    }
    if (key) {
      result[key as keyof IEditUserResponse] = { ...res };
    }
  });
  return result;
};

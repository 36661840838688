import { makeAutoObservable, runInAction } from "mobx";

import { DeliveryApi } from "./api";
import { IDelivery, IDeliveryType } from "./model";

import { NotificationType } from "shared/ui/Notification";

const { getDeliveryList, getDelivery, createDelivery, editDelivery, deleteDelivery, getAddressSuggestions } =
  DeliveryApi();

export class UserDeliveryStore {
  deliveries: IDelivery[] | undefined = undefined;
  delivery: IDelivery | undefined = undefined;
  types: IDeliveryType[] | undefined = undefined;
  loading = false;
  saving = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadDeliveries = async () => {
    this.loading = true;
    const response = await getDeliveryList();
    runInAction(() => {
      if (response.result) {
        this.deliveries = [...response.result];
      }
      this.loading = false;
    });
  };

  loadDelivery = async (id: string | number) => {
    const response = await getDelivery({ id });
    runInAction(() => {
      if (response.result) {
        this.delivery = { ...response.result };
      }
    });
  };

  createDelivery = async (delivery: Omit<IDelivery, "id">) => {
    this.saving = true;
    const response = await createDelivery({ ...delivery });
    return runInAction(() => {
      this.saving = false;
      if (response.result) {
        this.loadDeliveries();
        return { status: "success" as NotificationType };
      }
      if (response.error) {
        if (typeof response.error.data === "object") {
          return { status: "error" as NotificationType, fields: { ...response.error.data } };
        }
      }
      return { status: "error" as NotificationType };
    });
  };

  editDelivery = async (delivery: IDelivery) => {
    this.saving = true;
    const response = await editDelivery({ ...delivery });
    return runInAction(() => {
      this.saving = false;
      if (response.result) {
        this.loadDeliveries();
        return { status: "success" as NotificationType };
      }
      if (response.error) {
        if (typeof response.error.data === "object") {
          return { status: "error" as NotificationType, fields: { ...response.error.data } };
        }
      }
      return { status: "error" as NotificationType };
    });
  };

  deleteDelivery = async (id: string | number) => {
    this.saving = true;
    const response = await deleteDelivery({ id });
    return runInAction(() => {
      this.saving = false;
      if (response.result) {
        this.loadDeliveries();
        return { status: "success" as NotificationType };
      }
      if (response.error) {
        if (typeof response.error.data === "object") {
          return { status: "error" as NotificationType, fields: { ...response.error.data } };
        }
      }
      return { status: "error" as NotificationType };
    });
  };

  loadAddressesSuggestions = async (term: string): Promise<string[]> => {
    console.log(term);
    const response = await getAddressSuggestions({ term });
    return runInAction(() => {
      if (response.result) {
        return [...response.result];
      }
      return [];
    });
  };

  updateDeliveries = (deliveries?: IDelivery[]) => {
    this.deliveries = deliveries;
  };

  updateTypes = (types: IDeliveryType[]) => {
    this.types = types;
  };
}

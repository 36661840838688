import { FC, useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import { useDocumentTitle } from "usehooks-ts";

import { useStore } from "entities/context";

import { BaseLayoutProps } from "../types";

import classes from "./BaseLayout.module.scss";

import { Background } from "widgets/Background";
import { Footer } from "widgets/Footer";
import { Header } from "widgets/Header";

import { defaultBatch } from "shared/api";
import { Container } from "shared/ui/Container";
import { Loader } from "shared/ui/Loader";
import { sendYandexMetrik } from "shared/utils";

const URLS_HIDE_BACKGROUND = [""];

export const BaseLayout: FC<BaseLayoutProps> = observer(({ children, showFooter = true, title = "Guruleads" }) => {
  const {
    userStore: {
      balance,
      contacts,
      user,
      loading,
      activeTickets,
      updateUser,
      updateBalance,
      updateActiveTickets,
      updatePaymentsCount,
    },
    notificationStore: { updateCount },
    settingsStore: { background, updateBackground },
  } = useStore();
  useDocumentTitle(title);
  const location = useLocation();
  const [hideBackground, setHideBackground] = useState(false);

  const request = async () => {
    return await defaultBatch().then((response) => {
      if (response.user && response.user.result) {
        updateUser({ ...response.user.result, system: 1 });
      }
      if (response.balance && response.balance.result) {
        updateBalance({ ...response.balance.result });
      }
      if (response.notification && response.notification.result) {
        updateCount(response.notification.result.count);
      }
      if (response.background && response.background.result && !Array.isArray(response.background.result)) {
        updateBackground({ ...response.background.result });
      }
      if (response.ticketsCount && response.ticketsCount.result) {
        updateActiveTickets(response.ticketsCount.result);
      }
      if (response.paymentsCount && response.paymentsCount.result) {
        updatePaymentsCount(response.paymentsCount.result);
      }
    });
  };

  useEffect(() => {
    if (user === undefined || contacts === undefined || balance === undefined) {
      request();
    }
    if (URLS_HIDE_BACKGROUND.includes(location.pathname)) {
      setHideBackground(true);
    }
  }, []);
  useEffect(() => {
    sendYandexMetrik("hit");
  }, [location]);

  return (
    <>
      {loading ? (
        <Loader icon loading size="large" absolute />
      ) : (
        <>
          {!hideBackground && background && <Background thumbnails={background?.thumbnails} link={background.link} />}
          <Header />
          <Container withBackground={!hideBackground && background !== undefined}>
            <div className={classes.container}>{children}</div>
          </Container>
          {showFooter && <Footer count={activeTickets ?? null} />}
        </>
      )}
    </>
  );
});

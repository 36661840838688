import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { contractsRoutes } from "./ContractsRoutes";
import { mainRoutes } from "./MainRoutes";
import { notificationsRoutes } from "./NotificationsRoutes";
import { postbacksRoutes } from "./PostbacksRoutes";
import { profileRoutes } from "./ProfileRoutes";
import { promotionsRoutes } from "./PromotionsRoutes";

const router = createBrowserRouter([
  ...mainRoutes,
  ...profileRoutes,
  ...notificationsRoutes,
  ...postbacksRoutes,
  ...contractsRoutes,
  ...promotionsRoutes,
]);

export const LayoutRoutes = () => <RouterProvider router={router} />;

import axios, { AxiosResponse } from "axios";

const BASE_URL: string = process.env.REACT_APP_AUTH_URL ?? "";
const UNAUTH_URL: string = process.env.REACT_APP_PUBLIC_URL ?? "";

interface ResponseDataProps extends Omit<AxiosResponse, "data"> {
  data: JsonrpcResponse[];
}

export interface JsonrpcRequest {
  jsonrpc: string;
  id: string;
  method: string;
  params?: Record<string, any>;
}

export interface JsonrpcResponse {
  jsonrpc?: string;
  id: string;
  result?: any;
  error?: {
    code: number;
    data?: Record<string, string> | string;
    message: string;
  };
}

export const axiosInstance = axios.create({
  withCredentials: true,
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
  },
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && Array.isArray(error.response.data)) {
      const res = error.response.data.some((req: any) => req.result);
      if (res) {
        return error.response;
      }

      const unAuth = error.response.data.some((req: any) => req.error.code === 403);
      if (unAuth) {
        document.location.assign(UNAUTH_URL);
      }
    }

    return Promise.reject(error);
  },
);

export const requestBatch = async (data: JsonrpcRequest[]): Promise<JsonrpcResponse[]> => {
  return await axiosInstance
    .post(BASE_URL, data)
    .then((response: ResponseDataProps) => response.data)
    .catch((error) => {
      console.warn(error);
      return data.map((item) => ({
        id: item.id,
        error: {
          code: 500,
          message: error,
        },
      }));
    });
};

export const request = async (data: JsonrpcRequest[]): Promise<JsonrpcResponse> => {
  const result = await requestBatch(data);
  return result[0];
};

import { ReactNode } from "react";

import { ReactComponent as AccountWalletIcon } from "@material-symbols/svg-400/rounded/account_balance_wallet.svg";
import { ReactComponent as AccountIcon } from "@material-symbols/svg-400/rounded/account_circle.svg";
import { ReactComponent as StarIcon } from "@material-symbols/svg-400/rounded/award_star.svg";
import { ReactComponent as ContractIcon } from "@material-symbols/svg-400/rounded/contract.svg";
import { ReactComponent as DownloadIcon } from "@material-symbols/svg-400/rounded/download.svg";
import { ReactComponent as NotificationsIcon } from "@material-symbols/svg-400/rounded/notifications.svg";
import { ReactComponent as SettingsIcon } from "@material-symbols/svg-400/rounded/tune.svg";
import { Path } from "react-router-dom";

import { SystemType } from "entities/User/model";

export interface RouteProps extends Omit<Partial<Path>, "search"> {
  search?: Record<string, any>;
}
export interface LinkProps {
  id: number;
  label: ReactNode;
  route: RouteProps;
  hasPaymentsCount?: boolean;
  isReact?: boolean;
}

interface Props {
  id: number;
  path?: string;
  label: ReactNode;
  hasPaymentsCount?: boolean;
  hideForSystem?: SystemType;
  icon?: ReactNode;
  links?: LinkProps[];
  isReact?: boolean;
}

export const routes: Props[] = [
  {
    id: 1,
    path: `${process.env.REACT_APP_ACCOUNT_URL}/dashboard`,
    label: "Главная",
    isReact: false,
  },
  {
    id: 2,
    label: "Статистика",
    links: [
      {
        id: 1,
        route: {
          pathname: `${process.env.REACT_APP_ACCOUNT_URL}/stats/daily`,
        },
        label: "Общая",
        isReact: false,
      },
      {
        id: 2,
        route: {
          pathname: `${process.env.REACT_APP_ACCOUNT_URL}/stats-gambling`,
        },
        isReact: false,
        label: (
          <>
            Гемблинг <StarIcon />
          </>
        ),
      },
    ],
  },
  {
    id: 3,
    label: "Офферы",
    links: [
      {
        id: 1,
        route: {
          pathname: `${process.env.REACT_APP_ACCOUNT_URL}/offers`,
        },
        label: "Все офферы",
        isReact: false,
      },
      {
        id: 2,
        route: {
          pathname: `${process.env.REACT_APP_ACCOUNT_URL}/offers?orderDesc=1&orderBy=payment&page=1&onlySubscribed=1`,
          search: {
            orderDesc: 1,
            orderBy: "payment",
            page: 1,
            onlySubscribed: 1,
          },
        },
        label: "Избранные офферы",
        isReact: false,
      },
      {
        id: 3,
        route: {
          pathname: `${process.env.REACT_APP_ACCOUNT_URL}/offers?inPromotion=1`,
          search: {
            inPromotion: 1,
          },
        },
        label: "Акционные офферы",
        isReact: false,
      },
    ],
  },
  {
    id: 4,
    path: `${process.env.REACT_APP_ACCOUNT_URL}/promotions`,
    label: "Акции",
    isReact: false,
    hideForSystem: 3,
  },
  {
    id: 5,
    label: "Биллинг",
    hasPaymentsCount: true,
    links: [
      {
        id: 1,
        route: {
          pathname: `${process.env.REACT_APP_ACCOUNT_URL}/billing`,
        },
        label: "Выплаты",
        hasPaymentsCount: true,
        isReact: false,
      },
      {
        id: 2,
        route: {
          pathname: `${process.env.REACT_APP_ACCOUNT_URL}/profile/payment`,
        },
        label: "Платежная информация",
        isReact: false,
      },
      {
        id: 3,
        route: {
          pathname: `${process.env.REACT_APP_ACCOUNT_URL}/profile/contracts`,
        },
        label: "Договоры",
        isReact: false,
      },
      {
        id: 4,
        route: {
          pathname: `${process.env.REACT_APP_ACCOUNT_URL}/affiliates/billing-act/index`,
        },
        label: "Акты",
        isReact: false,
      },
    ],
  },
  {
    id: 6,
    label: "Инструменты",
    links: [
      {
        id: 1,
        route: {
          pathname: `${process.env.REACT_APP_ACCOUNT_URL}/profile/showcase`,
        },
        label: (
          <>
            Конструктор витрин <StarIcon />
          </>
        ),
        isReact: false,
      },
      {
        id: 2,
        route: {
          pathname: `${process.env.REACT_APP_ACCOUNT_URL}/conversions-loader`,
        },
        label: (
          <>
            Загрузчик конверсий <StarIcon />
          </>
        ),
        isReact: false,
      },
      {
        id: 3,
        route: {
          pathname: `${process.env.REACT_APP_ACCOUNT_URL}/profile/back-link`,
        },
        label: "Кнопка «Назад»",
        isReact: false,
      },
      {
        id: 4,
        route: {
          pathname: `${process.env.REACT_APP_ACCOUNT_URL}/profile/postbacks-global`,
        },
        label: "Глобальные постбеки",
        isReact: false,
      },
      {
        id: 5,
        route: {
          pathname: `${process.env.REACT_APP_ACCOUNT_URL}/profile/parking-domain`,
        },
        label: "Парковка доменов",
        isReact: false,
      },
      {
        id: 6,
        route: {
          pathname: `${process.env.REACT_APP_ACCOUNT_URL}/profile/offers-broken-links-traffic`,
        },
        label: "Битые ссылки",
        isReact: false,
      },
      {
        id: 7,
        route: {
          pathname: `${process.env.REACT_APP_ACCOUNT_URL}/profile/trafficback`,
        },
        label: "Trafficback-ссылки",
        isReact: false,
      },
      {
        id: 8,
        route: {
          pathname: `${process.env.REACT_APP_ACCOUNT_URL}/profile/api-instructions`,
        },
        label: "Инструкция к API",
        isReact: false,
      },
    ],
  },
];

export const profileRoutes: Props[] = [
  {
    id: 11,
    path: "/profile",
    label: "Мои данные",
    icon: <AccountIcon />,
    isReact: true,
  },
  {
    id: 13,
    path: `${process.env.REACT_APP_ACCOUNT_URL}/profile/payment`,
    label: "Мои реквизиты",
    icon: <AccountWalletIcon />,
    isReact: false,
  },
  {
    id: 15,
    path: `${process.env.REACT_APP_ACCOUNT_URL}/profile/contracts`,
    label: "Договоры",
    icon: <ContractIcon />,
    isReact: false,
  },
  // {
  //   id: 16,
  //   path: "/profile/history",
  //   label: "История экспорта",
  //   icon: <DownloadIcon />,
  // },
  {
    id: 12,
    path: "/api-settings",
    label: "Настройки API",
    icon: <SettingsIcon />,
    isReact: true,
  },
  {
    id: 14,
    path: `${process.env.REACT_APP_ACCOUNT_URL}/profile/notifications`,
    label: "Уведомления",
    icon: <NotificationsIcon />,
    isReact: false,
  },
];
